import React from "react"
import { useContext } from "react"
import { Link } from "gatsby"

// @material-ui/icons
import { makeStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Chip from "@material-ui/core/Chip"
import Divider from "@material-ui/core/Divider"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import Grid from "@material-ui/core/Grid"
import Hidden from "@material-ui/core/Hidden"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Typography from "@material-ui/core/Typography"

// core components
import Moment from "react-moment"
import "moment-timezone"

import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "context/GlobalContextProvider"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  expansion: {
    backgroundColor: "lightgray",
    flexShrink: 0,
    width: "auto",
  },
}))

function MobileGroup({ ...props }) {
  const classes = useStyles()
  const [expanded, setExpanded] = React.useState(false)
  const dispatch = useContext(GlobalDispatchContext)

  const groups = props.input

  const handleChange = (panel) => (_, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <div className={classes.root}>
      {groups.map((group) => {
        const empty = group.groupOpen === "已額滿" ? true : false
        return (
          <ExpansionPanel
            expanded={expanded === group.groupName + group.groupFee}
            onChange={handleChange(group.groupName + group.groupFee)}
            key={group.uniqueID}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Grid container justify="space-between">
                <Typography className={classes.heading} align="left">
                  <Moment tz="America/Yellowknife" format="M/DD">
                    {group.groupStart}
                  </Moment>{" "}
                  -{" "}
                  <Moment tz="America/Yellowknife" format="M/DD">
                    {group.groupEnd}
                  </Moment>
                </Typography>
                <Chip
                  label={group.groupOpen}
                  variant={empty ? "outlined" : "default"}
                  color={empty ? "default" : "primary"}
                />
              </Grid>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.expansion}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">
                    日期:{" "}
                    <Moment tz="America/Yellowknife" format="M/DD">
                      {group.groupStart}
                    </Moment>{" "}
                    -{" "}
                    <Moment tz="America/Yellowknife" format="M/DD">
                      {group.groupEnd}
                    </Moment>
                    <Divider light />
                    團費: ${group.groupFee}
                    <Divider light />
                    團名/代號: {group.groupName}
                    <Divider light />
                    導遊: {group.guides}
                    <Divider light />
                    空位: {group.groupOpen}
                  </Typography>
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
            <ExpansionPanelActions className={classes.expansion}>
              <Link
                to="/checkout"
                onClick={() =>
                  dispatch({
                    type: "SET_SELECTED",
                    value: group.groupName,
                  })
                }
              >
                <Button
                  color="primary"
                  variant={
                    group.groupOpen === "已額滿" ? "outlined" : "contained"
                  }
                >
                  {group.groupOpen === "已額滿" ? "我要候補" : "我要報名"}
                </Button>
              </Link>
            </ExpansionPanelActions>
          </ExpansionPanel>
        )
      })}
    </div>
  )
}

function BrowserGroup({ ...props }) {
  const dispatch = useContext(GlobalDispatchContext)
  const groups = props.input
  return (
    <TableContainer>
      <Table stickyHeader aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">日期</TableCell>
            <TableCell>費用(加幣)</TableCell>
            <TableCell>團名/代號</TableCell>
            <Hidden smDown>
              <TableCell>導遊</TableCell>
            </Hidden>
            <TableCell>空位</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {groups.map((group) => {
            return (
              <TableRow key={group.uniqueID}>
                <TableCell align="center" component="th" scope="row">
                  <Moment tz="America/Yellowknife" format="M/DD">
                    {group.groupStart}
                  </Moment>{" "}
                  -{" "}
                  <Moment tz="America/Yellowknife" format="M/DD">
                    {group.groupEnd}
                  </Moment>
                </TableCell>
                <TableCell>${group.groupFee}</TableCell>
                <TableCell>{group.groupName}</TableCell>
                <Hidden smDown>
                  <TableCell>{group.guides}</TableCell>
                </Hidden>
                <TableCell>{group.groupOpen}</TableCell>
                <TableCell>
                  <Link to="/checkout" activeClassName="button">
                    <Button
                      variant="contained"
                      style={{ fontSize: 16 }}
                      color={
                        group.groupOpen === "已額滿" ? "default" : "primary"
                      }
                      onClick={() =>
                        dispatch({
                          type: "SET_SELECTED",
                          value: group.groupName,
                        })
                      }
                    >
                      {group.groupOpen === "已額滿" ? "候補" : "報名"}
                    </Button>
                  </Link>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
function Groups({ ...props }) {
  const state = useContext(GlobalStateContext)

  const groups = state.groups

  const filtered = []

  if (props.groupType) {
    for (let group of groups) {
      if (!group) continue
      if (group.groupType === props.groupType) {
        filtered.push(group)
      }
    }
  }
  filtered.sort((a, b) => a.groupStart - b.groupStart)

  return (
    <div>
      <Hidden smUp>
        <MobileGroup input={filtered} {...props} />
      </Hidden>

      <Hidden xsDown>
        <BrowserGroup input={filtered} {...props} />
      </Hidden>
    </div>
  )
}

export default React.memo(Groups)
